import { paymentMethods } from '~/App/config/paymentMethods';
import { FormContent } from './formContent';

export const buildState = (formContent: FormContent) => ({
  product: {
    id: 32
  },
  paymentMethod: { id: paymentMethods.klarnaPayments },
  productOptions: {
    product: {
      price: formContent.selectedAmount,
      duration: '',
      period: 'Month'
    },
    campaignActivityId: 3339,
    pul: true
  }
});
