import React, { ReactNode } from 'react';
import styled from 'styled-components';

import mq from '~/App/helpers/mq';
import { heading1, heading3 } from '~/App/helpers/mixins';

import { MonthlyKlarnaDonationGala } from '~/App/shared/components/Donation/MonthlyKlarnaDonationGala';
import { IntegrityText } from '~/App/shared/components/Donation/components/Forms/components/IntegrityText';

const Heading = styled.h1`
  ${heading1};
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  margin: 2rem auto 0 auto;

  ${mq('≥small')`
    margin: 4rem auto 0 auto;
  `};
`;

const Text = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.25rem;
  text-align: center;
  margin: 0 auto;

  ${mq('<medium')`
    font-size: 1rem;
    margin: 0 auto 2rem auto;
  `};
`;

const Wrapper = styled.div`
  width: 48rem;
  max-width: calc(100% - 2rem);
  margin: 0 auto;
`;

const Card = styled.div`
  padding: 1.5rem;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  border-radius: 4px;

  ${mq('≥small')`
    padding: 1.5rem 0rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  `};
`;

const Image = styled.img`
  max-width: 50%;
  margin: 1rem auto 2rem;
  display: flex;

  ${mq('≥small')`
  margin: 4rem auto 4rem;
`};
`;

const CardContent = styled.div`
  width: 100%;
  max-width: 29rem;
`;

const CardHeading = styled.h2`
  ${heading3};
  color: ${({ theme }) => theme.colors.charcoal};
  text-align: center;
  margin: 0 0 1rem;
  font-size: 1.3rem;

  ${mq('≥small')`
    margin: 0.5rem 0 1.25rem;
  `};
`;

const StyledIntegrityText = styled(IntegrityText)`
  margin-top: 1rem;
`;

type Props = {
  source?: string;
  redirectPath: string;
  children: ReactNode;
};

export function Form({ source, redirectPath, children }: Props) {
  return (
    <Wrapper>
      <Heading>Bli månadsgivare</Heading>
      <Text>Tillsammans besegrar vi cancer</Text>
      <Card>
        <CardContent>
          <CardHeading>Välj belopp</CardHeading>
          <MonthlyKlarnaDonationGala
            redirectPath={redirectPath}
            source={source}
          />
          <StyledIntegrityText />
          {children}
        </CardContent>
      </Card>
      <Image src="https://res.cloudinary.com/cancerfonden/image/upload/f_auto,q_auto:eco/v1701178197/assets/STMC-logga-takeover_cropped.png" />
    </Wrapper>
  );
}
